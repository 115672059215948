import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  MIN_STEP,
  nextWizardStep,
  previousWizardStep,
  selectDescription,
  selectName,
  selectQuery,
  selectReportingFields,
  selectResultFields,
  selectTypeId,
  selectWizardStep,
  setDescription,
  setName,
  setQuery,
  setReportingFields,
  setResultFields,
} from "../../../store/slices/reportsSlice";
import {
  useCreateReportDefinitionFilterMutation,
  useGetReportDefinitionFiltersQuery,
  useReportingFieldsByTypeQuery,
  useUpdateReportDefinitionAggregationsMutation,
  useUpdateReportDefinitionFieldsMutation,
  useUpdateReportDefinitionFilterMutation,
  useUpdateReportDefinitionMutation,
} from "../../../store/slices/api/reportsApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { getTranslation } from "../../../util/utils";
import {
  addPropertyToChildren,
  checkQueryValidity,
  constructQuery,
  parseQuery,
} from "../../../util/reports-utils";
import ReportsWizardTimeline from "../create-report/ReportsWizardTimeline";
import BasicDataWizardStep from "../create-report/BasicDataWizardStep";
import DefineQueryWizardStep from "../create-report/DefineQueryWizardStep";
import FormatResultWizardStep from "../create-report/FormatResultWizardStep";
import AddWidgetWizardStep from "../create-report/AddWidgetWizardStep";
import { messageError } from "../../../util/notification";
import {
  CreateReportTypography,
  CreateReportWrapper,
  DefineQueryWizardStepWrapper,
  ReportsWizardBackButton,
  ReportsWizardContinueButton,
  ReportsWizardGridContainer,
  ReportsWizardRightSectionGridContainer,
  SaveAndContinueWrapper,
} from "../../styles/reports/Reports.styles";

const UpdateReportsWizard = ({
  handleClose,
  reportDefinitionData,
  reportDefinitionFields,
  reportDefinitionAggregations,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const wizardStep = useSelector(selectWizardStep);
  const name = useSelector(selectName);
  const description = useSelector(selectDescription);
  const reportingFields = useSelector(selectReportingFields);
  const resultFields = useSelector(selectResultFields);
  const query = useSelector(selectQuery);
  const typeId = useSelector(selectTypeId);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const filters = [parseQuery(query, reportingFields)];
  const isQueryValid = checkQueryValidity(query);
  const continueButtonDisabled =
    !name || resultFields?.some((field) => !field.displayName);

  // Mutations
  const [createReportDefinitionFilter] =
    useCreateReportDefinitionFilterMutation();
  const [updateReportDefinition] = useUpdateReportDefinitionMutation();
  const [updateReportDefinitionFilter] =
    useUpdateReportDefinitionFilterMutation();
  const [updateReportDefinitionFields] =
    useUpdateReportDefinitionFieldsMutation();
  const [updateReportDefinitionAggregations] =
    useUpdateReportDefinitionAggregationsMutation();

  // Queries
  const { data: reportingFieldsData } = useReportingFieldsByTypeQuery({
    organizationId,
    typeId: reportDefinitionData?.reportTypeId,
  });

  const { data: reportDefinitionFilterData } =
    useGetReportDefinitionFiltersQuery({
      organizationId,
      reportDefinitionId: reportDefinitionData?.id,
    });

  // Handlers
  const handleMoveBack = () => dispatch(previousWizardStep());

  const handleMoveNext = async () => {
    try {
      if (wizardStep === 1) {
        const reportDefinition = {
          reportTypeId: typeId,
          name,
          description,
        };

        await updateReportDefinition({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          reportDefinition,
        }).unwrap();
      } else if (wizardStep === 2) {
        const modifiedFilters = addPropertyToChildren(
          filters,
          "reportDefinitionId",
          reportDefinitionData.id
        );

        for (let i = 0; i < modifiedFilters.length; i++) {
          const { logicalOperator, conditions, children } = modifiedFilters[i];
          const reportDefinitionFilter = {
            logicalOperator,
            conditions,
            children,
          };

          if (reportDefinitionFilterData.length >= 1) {
            await updateReportDefinitionFilter({
              organizationId,
              reportDefinitionId: reportDefinitionData.id,
              reportDefinitionFilterId: reportDefinitionFilterData[i].id,
              reportDefinitionFilter,
            }).unwrap();
          } else {
            await createReportDefinitionFilter({
              organizationId,
              reportDefinitionId: reportDefinitionData.id,
              reportDefinitionFilter,
            }).unwrap();
          }
        }
      } else if (wizardStep === 3) {
        let reportDefinitionFields = [];
        let reportDefinitionAggregations = [];

        for (let i = 0; i < resultFields.length; i++) {
          const {
            field,
            usedForGrouping,
            aggregationTypes,
            orderIndex,
            sortMethod,
            displayName,
          } = resultFields[i];

          const reportingField = reportingFields.find(
            (reportingField) => reportingField.name === field
          );

          if (!reportingField) {
            continue;
          }

          const { id: reportFieldId } = reportingField;

          const existingReportDefinitionField = reportDefinitionFields.find(
            (f) => f.fieldId === reportFieldId
          );

          if (!Boolean(existingReportDefinitionField)) {
            const reportDefinitionField = {
              fieldId: reportFieldId,
              usedForGrouping,
              orderIndex,
              sortMethod,
              index: i,
              displayName,
            };

            aggregationTypes.forEach((aggregationType) => {
              reportDefinitionAggregations.push({
                fieldId: reportFieldId,
                aggregationType,
              });
            });

            reportDefinitionFields.push(reportDefinitionField);
          } else {
            if (usedForGrouping) {
              existingReportDefinitionField.usedForGrouping = usedForGrouping;
            }
          }
        }

        await updateReportDefinitionFields({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          reportDefinitionFields,
        }).unwrap();

        await updateReportDefinitionAggregations({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          reportDefinitionAggregations,
        }).unwrap();

        handleClose();

        return;
      }

      dispatch(nextWizardStep());
    } catch (error) {
      console.error("Error moving next", error);
      messageError(error?.data?.message);
    }
  };

  const renderWizardContent = () => {
    if (wizardStep === 1) {
      return <BasicDataWizardStep showPrivate={false} />;
    } else if (wizardStep === 2) {
      return <DefineQueryWizardStep />;
    } else if (wizardStep === 3) {
      return <FormatResultWizardStep />;
    } else if (wizardStep === 4) {
      return <AddWidgetWizardStep />;
    }

    return <p>Invalid wizard step</p>;
  };

  useEffect(() => {
    // First Step
    dispatch(setDescription(reportDefinitionData.description));
    dispatch(setName(reportDefinitionData.name));

    // Second Step
    // filters
    if (reportDefinitionFilterData && reportDefinitionFilterData.length > 0) {
      const constructedQuery = constructQuery(reportDefinitionFilterData[0]);
      dispatch(setQuery(constructedQuery));
    }

    // Third step
    // fields

    if (reportingFieldsData) {
      const formattedResultFields = reportDefinitionFields
        ?.slice()
        ?.sort((a, b) => a.index - b.index)
        .map((reportDefinitionField) => {
          const {
            field: { id: reportDefinitionFieldId },
            displayName,
            usedForGrouping,
            orderIndex,
            sortMethod,
          } = reportDefinitionField;
          const reportingField = reportingFieldsData.find(
            (f) => f.id === reportDefinitionFieldId
          );

          const aggregationTypes = reportDefinitionAggregations
            .filter((aggregation) => aggregation.field.id === reportingField.id)
            .map((a) => a.aggregationType);

          return {
            uniqueId: uuidv4(),
            displayName: getTranslation(
              displayName ?? reportingField.name,
              t,
              i18n
            ),
            field: reportingField.name,
            orderIndex,
            sortMethod,
            usedForGrouping: usedForGrouping,
            aggregationTypes,
          };
        });

      const formattedReportingFields = reportingFieldsData.map((field) => {
        return {
          id: field.id,
          name: field.name,
          label: field.name,
          validator: ({ value }) => Boolean(value),
        };
      });

      dispatch(setReportingFields(formattedReportingFields));
      dispatch(setResultFields(formattedResultFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportDefinitionData,
    reportingFieldsData,
    reportDefinitionFields,
    reportDefinitionAggregations,
    reportDefinitionFilterData,
  ]);

  return (
    <Box>
      <ReportsWizardGridContainer container>
        <Grid item xs={10}>
          <CreateReportWrapper>
            <CreateReportTypography variant="h5">
              {getTranslation("UPDATE_REPORT", t, i18n)}
            </CreateReportTypography>
            {renderWizardContent()}
          </CreateReportWrapper>
        </Grid>

        <ReportsWizardRightSectionGridContainer item xs={2}>
          <DefineQueryWizardStepWrapper>
            <Box>
              <ReportsWizardTimeline isEdit={true} />
            </Box>

            <SaveAndContinueWrapper>
              {wizardStep > MIN_STEP && (
                <ReportsWizardBackButton
                  id="back"
                  disabled={wizardStep <= MIN_STEP}
                  onClick={handleMoveBack}
                  variant="outlined"
                >
                  {getTranslation("back", t, i18n)}
                </ReportsWizardBackButton>
              )}

              <ReportsWizardContinueButton
                id="save"
                disabled={!isQueryValid || continueButtonDisabled}
                onClick={handleMoveNext}
                variant="contained"
              >
                {getTranslation("SAVE_AND_CONTINUE", t, i18n)}
              </ReportsWizardContinueButton>
            </SaveAndContinueWrapper>
          </DefineQueryWizardStepWrapper>
        </ReportsWizardRightSectionGridContainer>
      </ReportsWizardGridContainer>
    </Box>
  );
};

export default UpdateReportsWizard;
