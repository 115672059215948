import { useTranslation } from "react-i18next";
import getCroppedImg, { getTranslation } from "../util/utils";
import {
  CancelButton,
  CropperActionsBoxContainer,
  CropperStyledDialogActions,
  CropperStyledDialogContent,
  StyledDialog,
} from "./styles/general/General.styles";
import Cropper from "react-easy-crop";
import { useState } from "react";
import { Box, Slide, Slider, Typography } from "@mui/material";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrganizationData,
  selectUserData,
  setOrganizationData,
  setUserData,
} from "../store/slices/onboardingSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};

const CropEasy = ({
  photoURL,
  openCrop,
  setOpenCrop,
  setPhotoURL,
  setSelectedFile,
  submitUploadedImage,
  isUserOnboarding,
  isOrganizationOnboarding,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const userData = useSelector(selectUserData);
  const organizationData = useSelector(selectOrganizationData);

  // State
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Handlers
  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const file = await getCroppedImg(photoURL, croppedAreaPixels, rotation);
      const url = URL.createObjectURL(file);

      setPhotoURL(url);
      setSelectedFile(url);
      setOpenCrop(false);

      if (submitUploadedImage) {
        submitUploadedImage(file);
      }

      if (isUserOnboarding) {
        dispatch(setUserData({ ...userData, selectedFile: url, file }));
      }

      if (isOrganizationOnboarding) {
        dispatch(
          setOrganizationData({
            ...organizationData,
            selectedFile: url,
            file,
          })
        );
      }
    } catch (error) {
      console.error("Failed to crop the image", error);
    }
  };

  return (
    <StyledDialog
      fullWidth
      open={openCrop}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpenCrop(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <CropperStyledDialogContent>
        {/* Cropper */}
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </CropperStyledDialogContent>
      <CropperStyledDialogActions>
        <CropperActionsBoxContainer>
          <Box>
            <Typography>Zoom: </Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={zoomPercent}
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
          <Box>
            <Typography>Rotation: {rotation + "°"}</Typography>
            <Slider
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </Box>
        </CropperActionsBoxContainer>
      </CropperStyledDialogActions>
      <CancelButton id={`crop-button`} onClick={cropImage}>
        {getTranslation("CROP", t, i18n)}
      </CancelButton>
      <CancelButton id={`cancel-button`} onClick={() => setOpenCrop(false)}>
        {getTranslation("CANCEL", t, i18n)}
      </CancelButton>
    </StyledDialog>
  );
};

export default CropEasy;
