import {
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import KeycloakService from "../../services/KeycloakService";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import {
  useUpdateUserNameMutation,
  useUpdateUserNicknameMutation,
} from "../../store/slices/api/userManagementSlice";
import { selectUser, setUser } from "../../store/slices/authSlice";
import { messageError, messageSuccess } from "../../util/notification";
import { getTranslation, testInput } from "../../util/utils";
import { inputLabelProps } from "../inputs/BillingGroup";
import { ValidationText } from "../styles/inputs/NamesGroup.styles";
import {
  ActiveOrganizationHeading,
  ChangePasswordButton,
  FlexGrowContainer,
  KeepMeUpdatedLabel,
  OrganizationDesktopBoxContainer,
  OrganizationDesktopWrapper,
  OrganizationEditDesktopActions,
  OrganizationEditDesktopStickyFooter,
  OrganizationEditDiscardButton,
  OrganizationEditSaveButton,
} from "../styles/profile/ProfileDesktop.styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorHandling from "../common/ErrorHandling";
import { DESKTOP_SPACE, TABLET_SPACE } from "./OrganizationEditDesktop";
import ProfileDataStorageLocation from "./ProfileDataStorageLocation";
import ProfileAvatarDesktopHeading from "./ProfileAvatarDesktopHeading";

const ProfileDesktop = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);

  // State
  const [userData, setUserData] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    nickname: "",
  });
  const [originalUserData, setOriginalUserData] = useState({
    firstName: "",
    lastName: "",
    nickname: "",
  });

  const [emailValid] = useState(true);
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  // Mutations
  const [updateUserName] = useUpdateUserNameMutation();
  const [updateUserNickname] = useUpdateUserNicknameMutation();

  // Other variables
  const isValidFirstName = firstNameTouched
    ? testInput.USER_NAME(userData.firstName)
    : true;
  const isValidLastName = lastNameTouched
    ? testInput.USER_NAME(userData.lastName)
    : true;
  const isValidNickname = userData.nickname
    ? testInput.NICKNAME(userData.nickname)
    : true;

  const isInvalid = !isValidFirstName || !isValidLastName || !isValidNickname;

  const alert = {
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),

    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // Handlers
  const handleFirstNameChange = (event) => {
    setUserData({ ...userData, firstName: event.target.value });
    setFirstNameTouched(true);
  };

  const handleLastNameChange = (event) => {
    setUserData({ ...userData, lastName: event.target.value });
    setLastNameTouched(true);
  };

  const handleNicknameChange = (event) => {
    setUserData({ ...userData, nickname: event.target.value });
  };

  const checkHaveChanges = () => {
    if (originalUserData.nickname !== userData.nickname) {
      return true;
    }

    if (originalUserData.firstName !== userData.firstName) {
      return true;
    }

    if (originalUserData.lastName !== userData.lastName) {
      return true;
    }

    return false;
  };

  const handleEmailChange = (event) => {};

  const handleSubmit = useCallback(async () => {
    const isValidFirstName = testInput.USER_NAME(userData.firstName);
    const isValidLastName = testInput.USER_NAME(userData.lastName);

    if (!isValidFirstName) {
      messageError(
        `${getTranslation("firstName", t, i18n)}: ${getTranslation(
          "userInfoValidation",
          t,
          i18n
        )}`
      );
      return;
    }

    if (!isValidLastName) {
      messageError(
        `${getTranslation("lastName", t, i18n)}: ${getTranslation(
          "userInfoValidation",
          t,
          i18n
        )}`
      );
      return;
    }

    if (!isValidNickname) {
      messageError(
        `${getTranslation("nickname", t, i18n)}: ${getTranslation(
          "nicknameInfoValidation",
          t,
          i18n
        )}`
      );
      return;
    }

    try {
      setIsLoadingUpdate(true);
      const data = await updateUserName({
        userId: user.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
      }).unwrap();

      const nickNameData = await updateUserNickname({
        userId: user.id,
        nickname: Boolean(userData.nickname) ? userData.nickname : null,
      }).unwrap();

      if (data && nickNameData) {
        const newCurrentUserData = {
          firstName: data.firstName,
          lastName: data.lastName,
          nickname: nickNameData.nickname ?? "",
        };

        dispatch(
          setUser({
            ...user,
            ...newCurrentUserData,
          })
        );

        setOriginalUserData(newCurrentUserData);
      }
      messageSuccess(getTranslation("successfulUpdateUserName", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedUpdateUserName", t, i18n));
    } finally {
      setIsLoadingUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, dispatch]);

  const handleConfirmAlert = () =>
    setUserData({
      firstName: originalUserData.firstName,
      lastName: originalUserData.lastName,
      nickname: originalUserData.nickname,
    });

  const handleChangePassword = () => {
    const authUrl = KeycloakService.getAuthUrl();
    const frontEndUrl = window.location.protocol + "//" + window.location.host;
    const urlForChangePassword =
      authUrl +
      "?response_type=code&client_id=web-app&redirect_uri=" +
      frontEndUrl +
      "/profile&kc_action=UPDATE_PASSWORD";
    window.open(urlForChangePassword, "_self", "noreferrer");
  };

  // Other variables
  const haveChanges = checkHaveChanges();

  // Effects
  useEffect(() => {
    const currentUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      nickname: user.nickname ?? "",
    };

    setUserData(currentUser);
    setOriginalUserData(currentUser);
  }, []);

  return (
    <ErrorHandling isLoading={isLoadingUpdate} isError={false}>
      <OrganizationDesktopWrapper>
        <ConfirmAlert
          isOpen={openConfirm}
          setIsOpen={setOpenConfirm}
          alert={alert}
          handleConfirm={handleConfirmAlert}
        />

        <ProfileAvatarDesktopHeading />
        <OrganizationDesktopBoxContainer>
          <Stack rowGap={DESKTOP_SPACE}>
            <Grid
              container
              rowSpacing={{ sm: DESKTOP_SPACE, lg: TABLET_SPACE }}
              columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
            >
              <Grid item sm={12} lg={6}>
                <Grid
                  container
                  columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
                >
                  <Grid item sm={6}>
                    <TextField
                      inputProps={{
                        "data-testid": "first-name-input-onboarding",
                      }}
                      id="first-name-input-onboarding"
                      label={getTranslation("firstName", t, i18n)}
                      placeholder={getTranslation("firstName", t, i18n)}
                      variant="outlined"
                      value={userData.firstName}
                      onChange={handleFirstNameChange}
                      name="firstName"
                      error={!isValidFirstName}
                      InputLabelProps={inputLabelProps}
                      required
                    />

                    <Box>
                      {!isValidFirstName && (
                        <ValidationText
                          id="firstName-validation-text"
                          variant="body5"
                          align="center"
                        >
                          {getTranslation("userInfoValidation", t, i18n)}
                        </ValidationText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      inputProps={{
                        "data-testid": "last-name-input-onboarding",
                      }}
                      id="last-name-input-onboarding"
                      label={getTranslation("lastName", t, i18n)}
                      placeholder={getTranslation("lastName", t, i18n)}
                      variant="outlined"
                      value={userData.lastName}
                      onChange={handleLastNameChange}
                      name="lastName"
                      error={!isValidLastName}
                      InputLabelProps={inputLabelProps}
                      required
                    />

                    <Box>
                      {!isValidLastName && (
                        <ValidationText
                          id="lastName-validation-text"
                          variant="body5"
                          align="center"
                        >
                          {getTranslation("userInfoValidation", t, i18n)}
                        </ValidationText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} lg={6}>
                <Grid
                  container
                  columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
                >
                  <Grid item sm={6}>
                    <TextField
                      disabled
                      inputProps={{
                        "data-testid": "email-input-user-email-update",
                      }}
                      id="email-input-user-email-update"
                      label={getTranslation("WORKMAIL", t, i18n)}
                      placeholder={getTranslation("WORKMAIL", t, i18n)}
                      variant="outlined"
                      value={user.email}
                      onChange={handleEmailChange}
                      name="email"
                      error={!emailValid}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      inputProps={{
                        "data-testid": "nickname-input-user-nickname-update",
                      }}
                      id="nickname-input-user-nickname-update"
                      InputLabelProps={{
                        style: { color: theme.palette.secondary.main },
                      }}
                      label={getTranslation("nickname", t, i18n)}
                      variant="outlined"
                      value={userData.nickname}
                      onChange={handleNicknameChange}
                      name="nickname"
                      error={!isValidNickname}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={getTranslation("NICKNAME_INFO", t, i18n)}
                            >
                              <IconButton
                                aria-label="forum nickname info"
                                edge="end"
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Box>
                      {!isValidNickname && (
                        <ValidationText variant="body5" align="center">
                          {getTranslation("nicknameInfoValidation", t, i18n)}
                        </ValidationText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <ProfileDataStorageLocation />
          </Stack>

          <Box marginTop={8}>
            <ActiveOrganizationHeading variant="h5">
              {getTranslation("PASSWORD_AND_SECURITY", t, i18n)}
            </ActiveOrganizationHeading>

            <ChangePasswordButton onClick={handleChangePassword} variant="text">
              {getTranslation("CHANGE_PASSWORD", t, i18n)}
            </ChangePasswordButton>
          </Box>

          <FlexGrowContainer>
            <ActiveOrganizationHeading variant="h5">
              {getTranslation("NEWS_AND_OFFERS", t, i18n)}
            </ActiveOrganizationHeading>

            <KeepMeUpdatedLabel
              control={<Checkbox defaultChecked color="default" />}
              label={getTranslation("KEEP_ME_UPDATED", t, i18n)}
            />
          </FlexGrowContainer>

          <OrganizationEditDesktopStickyFooter>
            <Box>
              <Divider />
            </Box>

            <OrganizationEditDesktopActions>
              <OrganizationEditSaveButton
                onClick={handleSubmit}
                disabled={!haveChanges || isInvalid}
                variant="contained"
              >
                {getTranslation("SAVE", t, i18n)}
              </OrganizationEditSaveButton>

              <OrganizationEditDiscardButton
                onClick={() => setOpenConfirm(true)}
                disabled={!haveChanges}
                variant="outlined"
              >
                {getTranslation("DISCARD", t, i18n)}
              </OrganizationEditDiscardButton>
            </OrganizationEditDesktopActions>
          </OrganizationEditDesktopStickyFooter>
        </OrganizationDesktopBoxContainer>
      </OrganizationDesktopWrapper>
    </ErrorHandling>
  );
};

export default ProfileDesktop;
