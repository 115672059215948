import { useEffect, useRef } from "react";
import { contain, cover } from "../../util/intrinsic-scale";

const CanvasImage = ({ image, id, height, width, style, contains = true }) => {
  const canvasRef = useRef();

  useEffect(() => {
    if (image) {
      const canvas = canvasRef.current;
      if (canvas) {
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);

        const imageEl = new Image();
        imageEl.src = image;

        imageEl.onload = () => {
          if (contains) {
            const {
              offsetX,
              offsetY,
              width: finalWidth,
              height: finalHeight,
            } = contain(
              width,
              height,
              imageEl.naturalWidth,
              imageEl.naturalHeight
            );

            context.drawImage(
              imageEl,
              offsetX,
              offsetY,
              finalWidth,
              finalHeight
            );
          } else {
            const {
              offsetX,
              offsetY,
              width: finalWidth,
              height: finalHeight,
            } = cover(
              width,
              height,
              imageEl.naturalWidth,
              imageEl.naturalHeight
            );

            context.drawImage(
              imageEl,
              offsetX,
              offsetY,
              finalWidth,
              finalHeight
            );
          }
        };
      }
    }

    HTMLCanvasElement.prototype.toBlob = () => {};
    HTMLCanvasElement.prototype.toDataURL = () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <canvas
      id={id.includes(".") ? id.substring(0, id.indexOf(".")) : id}
      ref={canvasRef}
      style={style}
      width={width}
      height={height}
    />
  );
};

export default CanvasImage;
