import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ReportsWizardTimeline from "../../components/reports/create-report/ReportsWizardTimeline";
import DefineQueryWizardStep from "../../components/reports/create-report/DefineQueryWizardStep";
import FormatResultWizardStep from "../../components/reports/create-report/FormatResultWizardStep";
import AddWidgetWizardStep from "../../components/reports/create-report/AddWidgetWizardStep";
import BasicDataWizardStep from "../../components/reports/create-report/BasicDataWizardStep";
import {
  MIN_STEP,
  nextWizardStep,
  previousWizardStep,
  resetWizard,
  selectDescription,
  selectIsPrivate,
  selectName,
  selectQuery,
  selectReportDefinitionId,
  selectReportingFields,
  selectResultFields,
  selectTypeId,
  selectWizardStep,
  setReportDefinitionId,
  setReportingFields,
} from "../../store/slices/reportsSlice";
import {
  useCreateReportDefinitionFilterMutation,
  useCreateReportDefinitionMutation,
  useGetReportDefinitionFiltersQuery,
  useReportingFieldsByTypeQuery,
  useUpdateReportDefinitionAggregationsMutation,
  useUpdateReportDefinitionFieldsMutation,
  useUpdateReportDefinitionFilterMutation,
  useUpdateReportDefinitionMutation,
} from "../../store/slices/api/reportsApiSlice";
import { selectUser } from "../../store/slices/authSlice";
import { useEffect } from "react";
import ErrorHandling from "../../components/common/ErrorHandling";
import {
  addPropertyToChildren,
  checkQueryValidity,
  parseQuery,
} from "../../util/reports-utils";
import { getTranslation } from "../../util/utils";
import { useTranslation } from "react-i18next";
import { messageError } from "../../util/notification";
import {
  CreateReportTypography,
  CreateReportWrapper,
  DefineQueryWizardStepWrapper,
  ReportsWizardBackButton,
  ReportsWizardContinueButton,
  ReportsWizardGridContainer,
  ReportsWizardRightSectionGridContainer,
  SaveAndContinueWrapper,
} from "../../components/styles/reports/Reports.styles";

const ReportsWizard = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const wizardStep = useSelector(selectWizardStep);
  const typeId = useSelector(selectTypeId);
  const name = useSelector(selectName);
  const description = useSelector(selectDescription);
  const isPrivate = useSelector(selectIsPrivate);
  const reportingFields = useSelector(selectReportingFields);
  const resultFields = useSelector(selectResultFields);
  const query = useSelector(selectQuery);
  const reportDefinitionId = useSelector(selectReportDefinitionId);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  const filters = [parseQuery(query, reportingFields)];
  const isQueryValid = checkQueryValidity(query);
  const continueButtonDisabled =
    !name || resultFields?.some((field) => !field.displayName);

  // Queries
  const { data: reportingFieldsData, isLoading: isLoadingReportingFields } =
    useReportingFieldsByTypeQuery({
      organizationId,
      typeId,
    });

  const { data: reportDefinitionFilterData } =
    useGetReportDefinitionFiltersQuery(
      {
        organizationId,
        reportDefinitionId: reportDefinitionId,
      },
      {
        skip: !reportDefinitionId,
      }
    );

  // Mutations
  const [createReportDefinition] = useCreateReportDefinitionMutation();
  const [createReportDefinitionFilter] =
    useCreateReportDefinitionFilterMutation();

  const [updateReportDefinition] = useUpdateReportDefinitionMutation();
  const [updateReportDefinitionFilter] =
    useUpdateReportDefinitionFilterMutation();
  const [updateReportDefinitionFields] =
    useUpdateReportDefinitionFieldsMutation();
  const [updateReportDefinitionAggregations] =
    useUpdateReportDefinitionAggregationsMutation();

  // Handlers
  const handleMoveBack = () => dispatch(previousWizardStep());

  const handleMoveNext = async () => {
    try {
      if (wizardStep === 1) {
        const reportDefinition = {
          reportTypeId: typeId,
          name,
          description,
          userId: isPrivate ? user.id : null,
        };

        if (reportDefinitionId) {
          await updateReportDefinition({
            organizationId,
            reportDefinitionId,
            reportDefinition,
          }).unwrap();
        } else {
          const response = await createReportDefinition({
            organizationId,
            reportDefinition,
          }).unwrap();

          if (!response) {
            return;
          }

          const { id: reportDefinitionId } = response;
          dispatch(setReportDefinitionId(reportDefinitionId));
        }
      } else if (wizardStep === 2) {
        const modifiedFilters = addPropertyToChildren(
          filters,
          "reportDefinitionId",
          reportDefinitionId
        );
        for (let i = 0; i < modifiedFilters.length; i++) {
          const { logicalOperator, conditions, children } = modifiedFilters[i];
          const reportDefinitionFilter = {
            logicalOperator,
            conditions,
            children,
          };

          if (reportDefinitionFilterData.length >= 1) {
            await updateReportDefinitionFilter({
              organizationId,
              reportDefinitionId,
              reportDefinitionFilterId: reportDefinitionFilterData[i].id,
              reportDefinitionFilter,
            }).unwrap();
          } else {
            await createReportDefinitionFilter({
              organizationId,
              reportDefinitionId,
              reportDefinitionFilter,
            }).unwrap();
          }
        }
      } else if (wizardStep === 3) {
        let reportDefinitionFields = [];
        let reportDefinitionAggregations = [];

        for (let i = 0; i < resultFields.length; i++) {
          const {
            field,
            usedForGrouping,
            aggregationTypes,
            orderIndex,
            sortMethod,
            displayName,
          } = resultFields[i];

          const reportingField = reportingFields.find(
            (reportingField) => reportingField.name === field
          );

          if (!reportingField) {
            continue;
          }

          const { id: reportFieldId } = reportingField;

          let existingReportDefinitionField = reportDefinitionFields.find(
            (f) => f.fieldId === reportFieldId
          );

          if (!Boolean(existingReportDefinitionField)) {
            const reportDefinitionField = {
              fieldId: reportFieldId,
              usedForGrouping,
              orderIndex,
              sortMethod,
              index: i,
              displayName,
            };

            aggregationTypes.forEach((aggregationType) => {
              reportDefinitionAggregations.push({
                fieldId: reportFieldId,
                aggregationType,
              });
            });

            reportDefinitionFields.push(reportDefinitionField);
          } else {
            if (usedForGrouping) {
              existingReportDefinitionField.usedForGrouping = usedForGrouping;
            }
          }
        }

        if (reportDefinitionFields.length > 0) {
          await updateReportDefinitionFields({
            organizationId,
            reportDefinitionId,
            reportDefinitionFields,
          }).unwrap();
        }

        if (reportDefinitionAggregations.length > 0) {
          await updateReportDefinitionAggregations({
            organizationId,
            reportDefinitionId,
            reportDefinitionAggregations,
          }).unwrap();
        }

        dispatch(resetWizard());

        return;
      }

      dispatch(nextWizardStep());
    } catch (error) {
      console.error("Error moving next", error);
      messageError(error?.data?.message);
    }
  };

  // Renderers
  const renderWizardContent = () => {
    if (wizardStep === 1) {
      return <BasicDataWizardStep showPrivate={true} />;
    } else if (wizardStep === 2) {
      return <DefineQueryWizardStep />;
    } else if (wizardStep === 3) {
      return <FormatResultWizardStep />;
    } else if (wizardStep === 4) {
      return <AddWidgetWizardStep />;
    }

    return <p>Invalid wizard step</p>;
  };

  // Effects
  useEffect(() => {
    if (reportingFieldsData && reportingFieldsData.length > 0) {
      const formattedReportingFields = reportingFieldsData.map((field) => {
        return {
          id: field.id,
          name: field.name,
          label: field.name,
        };
      });

      dispatch(setReportingFields(formattedReportingFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportingFieldsData]);

  return (
    <ErrorHandling isLoading={isLoadingReportingFields} isError={false}>
      <Box>
        <ReportsWizardGridContainer container>
          <Grid item xs={10}>
            <CreateReportWrapper>
              <CreateReportTypography variant="h5">
                {getTranslation("CREATE_REPORT", t, i18n)}
              </CreateReportTypography>
              {renderWizardContent()}
            </CreateReportWrapper>
          </Grid>

          <ReportsWizardRightSectionGridContainer item xs={2}>
            <DefineQueryWizardStepWrapper>
              <Box>
                <ReportsWizardTimeline />
              </Box>

              <SaveAndContinueWrapper>
                {wizardStep > MIN_STEP && (
                  <ReportsWizardBackButton
                    id="back"
                    disabled={wizardStep <= MIN_STEP}
                    onClick={handleMoveBack}
                    variant="outlined"
                  >
                    {getTranslation("back", t, i18n)}
                  </ReportsWizardBackButton>
                )}

                <ReportsWizardContinueButton
                  id="save"
                  disabled={!isQueryValid || continueButtonDisabled}
                  onClick={handleMoveNext}
                  variant="contained"
                >
                  {getTranslation("SAVE_AND_CONTINUE", t, i18n)}
                </ReportsWizardContinueButton>
              </SaveAndContinueWrapper>
            </DefineQueryWizardStepWrapper>
          </ReportsWizardRightSectionGridContainer>
        </ReportsWizardGridContainer>
      </Box>
    </ErrorHandling>
  );
};

export default ReportsWizard;
